<template>
  <v-col sm="6" md="4" lg="2" class="px-2">
    <v-menu
      ref="menu"
      v-model="menuModal"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      :return-value.sync="itemValue"
      offset-y
      min-width="100px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          name="date-search"
          :label="label"
          :value="computedDateFormattedMomentjs"
          readonly
          v-bind="attrs"
          v-on="on"
        >
          <v-icon slot="append" title="removeFilter" @click="$emit('remove')">
            mdi-close
          </v-icon>
        </v-text-field>
      </template>
      <v-date-picker
        no-title
        range
        scrollable
        prepend-icon="mdi-calendar"
        v-model="itemValue"
      >
        <v-spacer></v-spacer>
        <v-btn text color="grey" @click="menuModal = false"> cancel </v-btn>
        <v-btn
          text
          color="primary"
          @click="
            $refs.menu.save(itemValue);
            handleInput();
          "
        >
          ok
        </v-btn>
      </v-date-picker>
    </v-menu>
  </v-col>
</template>

<script>
import moment from "moment";

export default {
  name: "DateSearch",
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {},
  },
  data: function () {
    return {
      menuModal: false,
      itemValue: this.value,
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      if (
        this.itemValue &&
        this.itemValue.length - 1 &&
        this.itemValue instanceof Array
      ) {
        let startValue = this.itemValue[0]
          ? moment(this.itemValue[0]).format("D MMM YYYY")
          : "";
        let endValue = this.itemValue[1]
          ? moment(this.itemValue[1]).format("D MMM YYYY")
          : "";
        return `${startValue}, ${endValue}`;
      } else if (this.itemValue && this.itemValue instanceof Array) {
        return this.itemValue[0]
          ? moment(this.itemValue[0]).format("D MMM YYYY")
          : "";
      } else {
        return this.itemValue
          ? moment(this.itemValue).format("D MMM YYYY")
          : "";
      }
    },
  },
  watch: {
    value: {
      handler: function () {
        this.itemValue = this.value;
      },
      deep: true,
    },
  },
  methods: {
    handleInput: function () {
      this.$emit("input", this.itemValue);
    },
  },
};
</script>
