<template>
  <div>
    <h1 class="outline--text">Custom sources</h1>
    <custom-source-search></custom-source-search>
    <v-row>
      <v-col class="outline--text mt-7" style="max-width: 700px">
        <div>
          <span v-if="!isLoading && this.totalCount > 0">
            <p v-if="this.totalCount > this.currentPage * this.itemsPerPage">
              Showing {{ (this.currentPage - 1) * this.itemsPerPage + 1 }}-{{
                this.currentPage * this.itemsPerPage
              }}
              of {{ this.totalCount }} items
            </p>
            <p v-else>
              Showing {{ (this.currentPage - 1) * this.itemsPerPage + 1 }}-{{
                this.totalCount
              }}
              of {{ this.totalCount }} items
            </p>
          </span>
        </div>
      </v-col>
      <v-col>
        <div class="d-flex justify-space-between">
          <v-spacer></v-spacer>
          <v-pagination
            v-if="showPages && pageCount > 1"
            class="my-3"
            v-model="pagination.page"
            :length="pageCount"
            :total-visible="7"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
          ></v-pagination>
          <div class="d-flex mx-10">
            <v-select
              v-model="pagination.rowPerPage"
              item-text="label"
              item-value="attribute"
              :items="countPerPage"
              label="Show items"
              style="max-width: 80px"
            >
            </v-select>
          </div>
          <div class="d-flex">
            <v-select
              item-text="label"
              item-value="attribute"
              :items="sortAttributes"
              label="sortBy"
              v-model="sort.sortBy"
              style="max-width: 180px"
            ></v-select>
            <span v-if="sort.sortBy" class="mt-5 ml-2">
              <v-icon
                v-if="sort.desc"
                title="sortAsc"
                @click="sort.desc = false"
              >
                mdi-sort-descending
              </v-icon>
              <v-icon v-else title="sortDesc" @click="sort.desc = true">
                mdi-sort-ascending
              </v-icon>
            </span>
            <v-btn
              depressed
              rounded
              small
              outlined
              icon
              color="grey darken-1"
              style="float: right"
              class="my-5 mx-6"
              @click="printItems"
              ><v-icon> mdi-printer-outline </v-icon></v-btn
            >
            <v-menu bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  rounded
                  small
                  outlined
                  icon
                  color="grey darken-1"
                  style="float: right"
                  class="my-5"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon> mdi-file-export-outline </v-icon></v-btn
                >
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, i) in exportChoices"
                  :key="i"
                  @click="exportItems(i)"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      id="itemsTable"
      :headers="headers"
      :loading="isLoading"
      :items="sources"
      :items-per-page="5"
      disable-pagination
      disable-filtering
      disable-sort
      hide-default-footer
      :item-class="itemClass"
    >
      <template v-slot:item.id="{ item }">
        <img
          v-if="item.isActive"
          class="mt-1"
          :src="require('@/assets/check-circle-outline-sm.png')"
          alt=""
        />
        <img
          v-else
          class="mt-1"
          :src="require('@/assets/close-circle-outline.png')"
          alt=""
        />
        {{ item.id }}
      </template>
      <template v-slot:item.name="{ item }">
        <router-link
          class="text--secondary"
          :to="{
            name: 'CustomSourceItemPage',
            params: { id: item.id },
          }"
        >
          <img v-bind:src="item.img" alt="" class="mr-2" />
          {{ item.name }}
        </router-link>
      </template>
      <template v-slot:item.parsingGroup="{ item }">
        <span v-if="!item.isSocial">
          {{ item.parsingGroup }}
        </span>
        <span v-else class="grey--text">NA</span>
      </template>
      <template v-slot:item.formattedSourceParsingStatus="{ item }">
        <div class="d-flex justify-center align-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="d-flex align-center">
                <img v-if="item.isSocial" alt="" src="" />
                <img
                  v-else-if="!item.isActive || item.channelsCount === 0"
                  :src="require('@/assets/broadcast-off.png')"
                  alt=""
                />
                <span v-else>
                  <span v-if="item.sourceParsingStatus?.success" class="d-flex align-center">
                    <img
                      :src="require('@/assets/check-circle-outline.png')"
                      alt=""
                    />
                    {{ item.sourceParsingStatus?.success }}
                  </span>
                  <span v-if="item.sourceParsingStatus?.warning" class="d-flex align-center">
                    <img
                      class="ml-2"
                      :src="require('@/assets/alert-outline.png')"
                      alt=""
                    />
                    {{ item.sourceParsingStatus?.warning }}
                  </span>
                  <span v-if="item.sourceParsingStatus?.error" class="d-flex align-center">
                    <img
                      class="ml-2"
                      :src="require('@/assets/alert-box-outline.png')"
                      alt=""
                    />
                    {{ item.sourceParsingStatus?.error }}
                  </span>
                </span>
              </div>
            </template>
            <p v-if="!item.isActive" class="my-2">Source disabled</p>
            <p v-else-if="item.isSocial" class="my-2 grey--text">NA</p>
            <p v-else-if="item.channelsCount === 0" class="my-2">No channels</p>
            <div v-if="item.sourceParsingStatus">
              <span class="green--text"
              >Success:{{ item.sourceParsingStatus.success ?? 0 }}</span
              ><br />
              <span class="orange--text"
              >Warning:{{ item.sourceParsingStatus.warning ?? 0 }}</span
              ><br />
              <span class="red--text"
              >Error:{{ item.sourceParsingStatus.error ?? 0 }}</span
              ><br />
            </div>
            <div v-else>NA</div>
          </v-tooltip>
          <span class="mt-1" v-if="!item.isActive"> Off </span>
          <span class="mt-1 px-3 grey--text" v-else-if="item.isSocial">
            NA
          </span>
          <span class="mt-1" v-else-if="item.channelsCount === 0">
            No channels
          </span>
        </div>
      </template>
      <template v-slot:item.relatedTagsCount="{ item }">
        <v-tooltip bottom :max-width="200">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-icon> mdi-tag-multiple-outline </v-icon>
              <span>{{ item.relatedTagsCount }}</span>
            </span>
          </template>
          <ul>
            <li v-for="i in item.relatedTagsList" v-bind:key="i">{{ i }}</li>
          </ul>
        </v-tooltip>
      </template>
      <template v-slot:item.articleParsingRate="{ item }">
        <span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ item.articleParsingRate }}
              </span>
            </template>
            <p class="my-2">Success rate</p>
          </v-tooltip>
          =<span class="red--text">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ item.articleParsingError }}</span
                >
              </template>
              <p class="my-2">Unsuccessful tasks</p>
            </v-tooltip></span
          >/<span class="green--text"
            ><v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{
                  item.articleParsingSuccess
                }}</span>
              </template>
              <p class="my-2">Successful tasks</p>
            </v-tooltip></span
          >
        </span>
      </template>
      <template v-slot:item.channelsCount="{ item }">
        <router-link
          :to="{
            name: 'CustomSourceItemPage',
            params: { id: item.id },
            query: { enabled: 'true' },
          }"
          ><v-tooltip bottom
            ><template v-slot:activator="{ on, attrs }"
              ><span class="green--text" v-bind="attrs" v-on="on">{{
                item.activeChannelsCount
              }}</span></template
            >
            <p class="my-0">Active channels</p></v-tooltip
          ></router-link
        >/<v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ item.channelsCount }}</span>
          </template>
          <p class="my-0">All channels</p>
        </v-tooltip>
      </template>

      <template v-slot:item.lastParsing="{ item }">
        <span class="grey--text">{{ item.lastParsing }}</span>
      </template>
      <template v-slot:item.nextParsing="{ item }">
        <span class="grey--text">{{ item.nextParsing }}</span>
      </template>

      <template v-slot:item.Actions="{ item }">
        <router-link
          v-if="canManage"
          :to="{
            name: 'CustomSourceItemPage',
            params: { id: item.id },
          }"
          ><v-icon class="mr-2" @click="editItem(item)">
            mdi-pencil-outline
          </v-icon></router-link
        >
      </template>
    </v-data-table>
    <v-dialog v-if="selectedItem" v-model="deleteDialog" max-width="450px">
      <v-card>
        <v-card-title
          class="headline primary lighten-1 white--text justify-space-between py-1"
        >
          Delete confirmation
          <v-btn icon color="white" @click="deleteDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-progress-linear
          v-if="isLoading"
          indeterminate
          color="primary"
        ></v-progress-linear>
        <v-card-text class="mt-6">
          Are you sure you want to delete {{ selectedItem.name }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="deleteDialog = false">Close</v-btn>
          <v-btn color="primary" text @click="deleteItem(selectedItem)">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import BaseListPage from "./base/BaseListPage";

import {
  defaultFilters,
  emptyFilters,
  multipleFilters,
} from "@/store/modules/customSource/state";
import {
  FETCH_CUSTOM_SOURCES,
  SET_FILTERS,
} from "@/store/modules/customSource/actions";
import { FETCH_CUSTOM_SOURCES_FILE } from "@/store/modules/customSource/actions";
import CustomSourceSearch from "@/views/customSource/list/CustomSourceSearch";
import { FETCH_CUSTOM_TAG_NAMES } from "@/store/modules/tag/actions";
import acl from "@/mixin/acl";
import { RESOURCE_CUSTOM_SOURCES_MANAGEMENT } from "@/store/modules/admin/getters";
export default {
  name: "CustomSourcesPage",
  extends: BaseListPage,
  mixins: [acl],
  components: { CustomSourceSearch },
  props: {
    limit: {
      required: false,
    },
  },
  data() {
    return {
      defaultFilters,
      emptyFilters,
      multipleFilters,
      showPages: false,
      countPerPage: [
        {
          attribute: 20,
          label: "20",
        },
        {
          attribute: 50,
          label: "50",
        },
        {
          attribute: 100,
          label: "100",
        },
        {
          attribute: 5000,
          label: "All",
        },
      ],
      headers: [
        {
          text: "Id",
          value: "id",
          width: "100",
        },
        {
          text: "Domain",
          value: "name",
          width: "200",
        },
        {
          text: "Rating",
          value: "domainRating",
          width: "50",
        },
        {
          text: "Tags",
          value: "relatedTagsCount",
          width: "100",
        },
        {
          text: "Group",
          value: "parsingGroup",
          width: "50",
          align: "center",
        },
        {
          text: "Channels",
          value: "channelsCount",
          width: "50",
          align: "center",
        },
        {
          text: "Articles",
          value: "totalParsed",
          width: "100",
          align: "center",
        },
        {
          text: "Last Run",
          value: "lastParsing",
          width: "175",
          align: "center",
        },
        {
          text: "Source Parsing",
          value: "formattedSourceParsingStatus",
          width: "160",
          align: "center",
        },
        {
          text: "Article Parsing",
          value: "articleParsingRate",
          width: "150",
        },
        {
          text: "Next Run",
          value: "nextParsing",
          width: "175",
          align: "center",
        },
        {
          text: "Active Projects",
          value: "activeProjectsCnt",
          width: "50",
          align: "center",
        },
        {
          text: "Account Id",
          value: "accountId",
          width: "50",
          align: "center",
        },
        {
          text: "Account Name",
          value: "accountName",
          width: "175",
          align: "center",
        },
        {
          text: "Date Create",
          value: "createdAt",
          width: "175",
          align: "center",
        },
        {
          width: "50",
          text: "",
          value: "Actions",
        },
      ],
      sortAttributes: [
        {
          attribute: "id",
          label: "ID",
        },
        {
          attribute: "isActive",
          label: "Enabled",
        },
        {
          attribute: "name",
          label: "Domain",
        },
        {
          attribute: "accountId",
          label: "Account Id",
        },
        {
          attribute: "totalParsed",
          label: "Articles",
        },
        {
          attribute: "channelsCount",
          label: "Channels",
        },
        {
          attribute: "articleParsingRate",
          label: "Article Parsing",
        },
        {
          attribute: "sourceParsingStatus",
          label: "Source Parsing",
        },
        {
          attribute: "createdAt",
          label: "Date Create",
        },
      ],
      exportChoices: [
        {
          title: "Sources",
        },
        {
          title: "Sources & channels",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("customSource", [
      "sourceList",
      "totalCount",
      "currentPage",
      "pageCount",
      "offset",
      "formattedFilters",
    ]),
    ...mapState("customSource", ["sort", "pagination", "filters"]),
    canManage: function () {
      return this.hasAccess([RESOURCE_CUSTOM_SOURCES_MANAGEMENT]);
    },
    itemsPerPage: function () {
      return this.pagination.rowPerPage;
    },
    sources: function () {
      if (this.isLoading) {
        return [];
      }
      return this.sourceList;
    },
  },
  watch: {
    "pagination.rowPerPage": function () {
      this.pagination.page = 1;
    },
  },
  methods: {
    applyFilters: function (payload) {
      return this.$store.dispatch(`customSource/${SET_FILTERS}`, payload.data);
    },
    fetchItems: function () {
      const vm = this;
      vm.isLoading = true;
      vm.$store.dispatch(`customSource/${FETCH_CUSTOM_SOURCES}`).then(
        () => {
          vm.isLoading = false;
          vm.showPages = true;
        },
        (error) => {
          console.log(error);
        }
      );
      this.$store.dispatch(`tag/${FETCH_CUSTOM_TAG_NAMES}`);
    },
    exportItems: function (option) {
      // option defines export type (0 - sources, 1 - sources & channels)
      const vm = this;
      vm.$store
        .dispatch(`customSource/${FETCH_CUSTOM_SOURCES_FILE}`, option)
        .then(
          () => {},
          (error) => {
            console.log(error);
          }
        );
    },
    printItems: function () {
      this.$htmlToPaper("itemsTable", null, () => {});
    },
  },
  mounted() {
    const vm = this;
    vm.applyRouteParams();
    vm.fetchItems();
  },
};
</script>
