<template>
  <v-col sm="6" md="4" lg="2">
    <v-select
      item-text="key"
      item-value="item"
      :items="enumItems"
      :label="label"
      v-model="itemValue"
      name="item"
      @input="handleInput"
    >
      <v-icon slot="append" title="removeFilter" @click="$emit('remove')">
        mdi-close
      </v-icon>
    </v-select>
  </v-col>
</template>

<script>
export default {
  name: "StatusEnumFilter",
  props: {
    statuses: [],
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {
      type: Number,
    },
    allowEmpty: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      availableItems: this.statuses,
      itemValue: this.value,
      closeOnContentClick: true,
    };
  },
  watch: {
    value: {
      handler: function () {
        this.itemValue = this.value;
      },
      deep: true,
    },
  },
  methods: {
    handleInput: function () {
      this.$emit("input", this.itemValue);
    },
  },
  computed: {
    enumItems: function () {
      let items = this.availableItems;
      if (this.allowEmpty) {
        items.unshift({
          key: "notSet",
          item: "notSet",
        });
      }
      return items;
    },
  },
};
</script>
