<template>
  <div class="pt-2">
    <v-row v-if="filtersReady">
      <component
        v-for="(item, index) in selectedCustomSourceFilters"
        :key="index"
        :is="item"
        :items="parsingGroups"
        :statuses="parsingStatus"
        :label="availableCustomSourceFilters[item]"
        v-model="filters[item]"
        @remove="removeFilter(item)"
      ></component>
      <v-col
        :sm="isEmpty(availableFilters) ? 6 : 12"
        :md="isEmpty(availableFilters) ? 4 : 8"
        :lg="isEmpty(availableFilters) ? 2 : 4"
        class="align-self-center"
      >
        <v-menu
          v-if="!isEmpty(availableFilters)"
          top
          :close-on-content-click="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              x-small
              outlined
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              class="mr-2"
            >
              <v-icon x-small>mdi-plus</v-icon>
              Add filter
            </v-btn>
          </template>

          <v-list width="250px">
            <v-list-item-group>
              <v-virtual-scroll
                :height="availableFilters.length * 40"
                :itemHeight="40"
                :items="availableFilters"
              >
                <template v-slot:default="{ item }">
                  <v-list-item>
                    <v-list-item-title @click="addFilter(item.key)">{{
                      item.label
                    }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-btn
          x-small
          outlined
          color="grey darken-1"
          dark
          @click="resetFilters"
        >
          reset Filters
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from "lodash";
import TextFilter from "@/views/components/searchParts/TextFilter";
import DateRangeFilter from "@/views/components/searchParts/DateRangeFilter";
import BooleanFilter from "@/views/components/searchParts/BooleanFilter";
import StatusEnumFilter from "@/views/components/searchParts/StatusEnumFilter";
import CustomTagAutoCompFilter from "@/views/components/searchParts/CustomTagAutoCompFilter";

import { mapGetters, mapState } from "vuex";
import { SET_EMPTY_FILTERS } from "@/store/modules/customSource/actions";
import { emptyFilters } from "@/store/modules/customSource/state";
import {
  REMOVE_CUSTOM_SOURCE_FILTER,
  ADD_CUSTOM_SOURCE_FILTER,
  SET_DEFAULT_CUSTOM_SOURCE_FILTERS,
  SET_CUSTOM_SOURCE_FILTERS,
} from "@/store/modules/persistentStorage/actions";
import ParsingGroupSearch from "@/views/components/searchParts/ParsingGroupSearch";

export default {
  name: "CustomSourceSearch",
  components: {
    id: TextFilter,
    name: TextFilter,
    accountId: TextFilter,
    accountName: TextFilter,
    channelUrl: TextFilter,
    enabled: BooleanFilter,
    tag: CustomTagAutoCompFilter,
    parsingGroup: ParsingGroupSearch,
    parsingStatus: StatusEnumFilter,
    lastRun: DateRangeFilter,
    projectId: TextFilter,
  },
  data: function () {
    return {
      filtersReady: false,
      parsingGroups: [
        {
          key: "0",
          item: 0,
        },
        {
          key: "1",
          item: 1,
        },
        {
          key: "2",
          item: 2,
        },
        {
          key: "3",
          item: 3,
        },
        {
          key: "4",
          item: 4,
        },
      ],
      parsingStatus: [
        {
          key: "Error",
          item: -1,
        },
        {
          key: "Warning",
          item: 0,
        },
        {
          key: "Success",
          item: 1,
        },
      ],
    };
  },
  computed: {
    ...mapState("customSource", ["filters"]),
    ...mapGetters("persistentStorage", [
      "selectedCustomSourceFilters",
      "availableCustomSourceFilters",
    ]),
    availableFilters: function () {
      let vm = this;
      let result = [];

      const filtersToSelect = _.difference(
        _.keys(emptyFilters),
        this.selectedCustomSourceFilters
      );

      filtersToSelect.forEach(function (filter) {
        result.push({
          key: filter,
          label: _.get(vm.availableCustomSourceFilters, filter),
        });
      });
      return result;
    },
  },
  watch: {
    selectedCustomSourceFilters: {
      handler: function () {
        let query = _.merge({}, this.$route.query);
        query["selectedFilters"] = _.merge(
          [],
          this.selectedCustomSourceFilters
        );
        query["page"] = this.$route.query.page;
        this.$router.push({ name: this.$route.name, query: {} }).catch((e) => {
          console.log(e);
        });
        this.$router
          .push({ name: this.$route.name, query: query })
          .catch(() => {});
      },
      deep: true,
    },
  },
  methods: {
    addFilter: function (filter) {
      this.$store.dispatch(
        `persistentStorage/${ADD_CUSTOM_SOURCE_FILTER}`,
        filter
      );
    },
    removeFilter: function (filter) {
      this.$store.dispatch(
        `persistentStorage/${REMOVE_CUSTOM_SOURCE_FILTER}`,
        filter
      );
      this.filters[filter] = null;
    },
    resetFilters: function () {
      this.$store.dispatch(`customSource/${SET_EMPTY_FILTERS}`);
      this.$store.dispatch(
        `persistentStorage/${SET_DEFAULT_CUSTOM_SOURCE_FILTERS}`
      );
    },
    isEmpty: function (value) {
      return _.isEmpty(value);
    },
  },
  mounted: function () {
    const vm = this;

    let query = Object.assign({}, this.$route.query);
    if (!_.isEmpty(query) && query["selectedFilters"]) {
      let selectedFilters = query["selectedFilters"];
      if (!(selectedFilters instanceof Array)) {
        selectedFilters = [selectedFilters];
      }
      this.$store.dispatch(
        `persistentStorage/${SET_CUSTOM_SOURCE_FILTERS}`,
        selectedFilters
      );
    }
    if (!_.isEmpty(query)) {
      _.forIn(query, function (value, filter) {
        if (_.find(vm.availableFilters, ["key", filter])) {
          vm.addFilter(filter);
        }
      });
    }
    vm.filtersReady = true;
  },
};
</script>
