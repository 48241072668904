<template>
  <v-col sm="7" md="5" lg="3">
    <v-text-field
      prepend-icon="mdi-magnify"
      name="textFilter"
      :label="label"
      v-model="filterValue"
      type="text"
      @change="handleInput"
    >
      <v-icon slot="append" title="removeFilter" @click="$emit('remove')">
        mdi-close
      </v-icon>
    </v-text-field>
  </v-col>
</template>

<script>
export default {
  name: "TextFilter",
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {
      type: String,
    },
  },
  data: function () {
    return {
      filterValue: this.value,
    };
  },
  watch: {
    value: {
      handler: function () {
        this.filterValue = this.value;
      },
      deep: true,
    },
  },
  methods: {
    handleInput: function () {
      this.$emit("input", this.filterValue);
    },
  },
};
</script>
